<template>
  <v-card>
    <v-card-title
      class="justify-space-between"
      >
      Recortar imagen

      <v-btn
        small
        @click="$emit('close')"
        icon
        >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text
      class="px-0"
      >
      <div
        ref="container"
        >
        <vue-croppie 
          :key="width"
          ref="croppieRef" 
          :boundary="{ width: width - 10, height: height - 10 }" 
          :viewport="{ width: width, height: height, 'type':'square' }"
          :enableResize="false"
          ></vue-croppie>
        <!-- the result -->
        <div
          class="text-center"
          >
          <v-btn
            @click="crop"
            depressed
            color="secondary"
            tile
            >
            Aceptar
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue';
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css' // import the croppie css manually

Vue.use(VueCroppie);

export default {
  data: () => ({
    width: 200,
    height: 200,
    filter: null
  }),

  props: {
    image: {
      required: true,
      type: File
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.width = (this.$refs.container.offsetWidth - 10)
      this.height = this.width

      setTimeout( () => {
        var reader = new FileReader()

        reader.onload = e => {
          this.$refs.croppieRef.bind({
            url: e.target.result
          })
        }

        reader.readAsDataURL(this.image)
      }, 300)
    })
  },

  methods: {
    crop() {
      // Options can be updated.
      // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
      let options = {
        type: 'base64',
        size: { width: 800, height: 800 },
        format: 'jpeg'
      }

      this.$refs.croppieRef.result(options, output => {
        this.$emit('crop', { image: output, filter: this.filter })
      })
    }
  }
}
</script>

<style scoped>
>>> *::-webkit-scrollbar {
  display: unset
}

</style>
